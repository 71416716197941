import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { createNewUser } from '../../../admin/store/admin.action';
import { ROLE, RoleFullDto } from '../../../authentication/model/role.model';
import { UserCreation } from '../../../admin/model/user-creation.model';
import { selectAllRoles } from '../../../admin/store/admin.selector';
import { getContextCode, isRoleSelected, toggleRole, getLabelForRole } from '../user-tools';
import { ContextValues } from '../../../admin/model/context-values.model';
import { map, shareReplay } from 'rxjs/operators';
import { UserAdminService } from '../../../admin/service/user-admin.service';

@Component({
  selector: 'sibat-new-user',
  templateUrl: 'new-user.component.html',
  styleUrls: ['new-user.component.scss'],
})
export class NewUserComponent implements OnInit {
  rolesList = Object.values(ROLE);
  userForm: UntypedFormGroup;
  roles$: Observable<RoleFullDto[]>;
  contextValuesCache$: { [key: string]: Observable<ContextValues[]> } = {};
  roles: UntypedFormControl;
  selectedRole: string;

  constructor(private store: Store, private formBuilder: UntypedFormBuilder, private userAdminService: UserAdminService) {}

  get roleContextValues(): UntypedFormArray {
    return this.userForm.get('roleContextValues') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.selectedRole = '';
    this.roles$ = this.store.select(selectAllRoles);
    this.userForm = this.formBuilder.group({
      lastName: ['', { validators: [Validators.required], updateOn: 'change' }],
      firstName: ['', { validators: [Validators.required], updateOn: 'change' }],
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
          updateOn: 'change',
        },
      ],
      phoneNumber: ['', { updateOn: 'change' }],
      roles: [[], { validators: [Validators.required], updateOn: 'change' }],
      role: ['', { validators: [Validators.required], updateOn: 'change' }],
      language: ['', { validators: [Validators.required], updateOn: 'change' }],
      municipalities: ['', { updateOn: 'change' }],
      roleContextValues: this.formBuilder.array([])
    });
  }

  selectedValue() {
    this.selectedRole = this.userForm.get('role')?.value;
  }

  getContextValuesForSelectedRole(roleCode: string): Observable<ContextValues[]> {
    const contextCode = this.getContextCodeForRole(roleCode);

    if (!this.contextValuesCache$[roleCode]) {
      if (!contextCode) {
        this.contextValuesCache$[roleCode] = of([]);
      } else {
        this.contextValuesCache$[roleCode] = this.userAdminService.fetchContextValues(roleCode).pipe(
          map(contextValues => contextValues.sort((a, b) => a.geoFieldAlias.localeCompare(b.geoFieldAlias))),
          shareReplay(1)
        );
      }
    }

    return this.contextValuesCache$[roleCode];
  }

  getContextCodeForRole(selectedRole: string): string | null {
    let contextLabel: string | null = null;
    this.roles$.subscribe(roles => {
      contextLabel = getContextCode(roles, selectedRole);
    });
    return contextLabel;
  }

  isRoleSelected(selectedRole: string): boolean {
    return isRoleSelected(this.userForm, selectedRole);
  }

  getLabelForRole(selectedRole: {name: string ; code: string}): string | null {
    return selectedRole.name;
  }

  toggleRole(changedRole: string) {
    if (changedRole === 'role_api') {
      return;
    }
    toggleRole(this.userForm, changedRole, this.roles$);
    this.initRoleContexts(this.userForm.get('roles')?.value);
  }

  onSubmit() {
    const newUser: UserCreation = {
      firstName: this.userForm.get('firstName')?.value,
      lastName: this.userForm.get('lastName')?.value,
      email: this.userForm.get('email')?.value,
      phoneNumber: this.userForm.get('phoneNumber')?.value,
      role: this.userForm.get('role')?.value,
      language: this.userForm.get('language')?.value,
      roles: this.userForm.get('roles')?.value,
      roleContextValues: this.cleanUpRoleContextValues(),
    };
    this.store.dispatch(createNewUser({ newUser }));
  }

  private initRoleContexts(roles: string[] | undefined): void {
    const contextValuesArray = this.userForm.get('roleContextValues') as UntypedFormArray;
    contextValuesArray.clear();

    if (!roles) {
      return;
    }

    roles.forEach(r => {
      const contextValueControl = this.formBuilder.group({
        roleCode: r,
        contextValues: this.formBuilder.control([], Validators.required),
      });
      contextValuesArray.push(contextValueControl);
    });
  }

  private cleanUpRoleContextValues() {
    const selectedRoles = this.userForm.get('roles')?.value || [];
    return this.roleContextValues.value
      .filter(({ roleCode, contextValues }) =>
        selectedRoles.some(selectedRole => selectedRole.code === roleCode.code) && contextValues.length > 0
      )
      .map(({ roleCode, contextValues }) => ({
        roleCode: roleCode.code,
        contextValues,
      }));
  }

}
